import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import checklogin from "./checkLogin";
import axios from "axios";
import Swal from "sweetalert2";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import infomationLoginUser from "./LoginUSerInfo";
import followups from "../Assets/Images/followup.jpg";
import OffCanvasSale from "./Followup";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useFormik } from "formik";
import { useForm } from 'react-hook-form';
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isWeekend } from "date-fns";

export default function LeadAssign({ paginationTotalRows, onPageChange, perPage, onPerPageChange, paginationRowsPerPageOptions, paginationPerPageLabel }) {
  const [data, setData] = useState([]);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState('');
  const [searchText, setSearchText] = useState("");
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [salesData, setSalesData] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [leadModal, setLeadModal] = useState(false);
  const [followupStatus, setFollowupStatus] = useState("Follow");
  const [followup, setFollowup] = useState(false);
  const [show, setShow] = useState(false);
  const [amountDeposit, amountDepositShow] = useState(false);
  const [id, editId] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [defaultCountryNumber, setDefaultCountryNumber] = useState('in');
  const [defaultCountry, setDefaultCountry] = useState('in');
  const [selectedId, setSelectedId] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [caseInitiatedChecked, setCaseInitiatedChecked] = useState(false);
  const [fileInitiatedChecked, setFileIntiatedChecked] = useState(false);
  const [docReceivedChecked, setDocsReceivedChecked] = useState(false);
  const [soppreprationChecked, setSoppreprationChecked] = useState(false);
  const [sopLetterChecked, setSopLettersChecked] = useState(false);
  const [confirmReceivedChecked, setConfirmRecievedChecked] = useState(false);
  const [fileSentChecked, setFilesentChecked] = useState(false);
  const [visaApprovedChecked, setVisaapprovedChecked] = useState(false);
  const [visaRefusalChecked, setVisaRefusalChecked] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leadStatus, setLeadStatus] = useState(" ");
  const [preview, setPreview] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(perPage || 10);
  const [totalRows, setTotalRows] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [profile, setProfile] = useState([]);
  const [serviceName, setServiceName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leadTypeList, setLeadTypeList] = useState([]);
  const [leadSourceList, setLeadSourceList] = useState([]);
  const initialTimezone = localStorage.getItem('timezone') || 'India';
  const [timezone, setTimezone] = useState(initialTimezone);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const handleClose = () => {
    resetForm();
    setShow(false);
  }

  const handleShow = (_id) => {
    setFollowupStatus("Follow");
    setSelectedId(_id);
    setShow(true);
    fetchData(_id);
  };

  useEffect(() => {
    const timerID = setInterval(() => {
      var currentDate = displayTime(timezone); // Assuming displayTime returns the current time
      setCurrentDate(currentDate)
    }, 1000);

    return () => clearInterval(timerID);
  }, [timezone]);

  const displayTime = (selectedTimezone) => {
    const date = new Date();
    let options = { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' };

    if (selectedTimezone === 'India') {
      options.timeZone = 'Asia/Kolkata';
    } else if (selectedTimezone === 'Canada') {
      options.timeZone = 'America/Toronto';
    }
    return date.toLocaleString('en-US', options).replace(/\//g, '-');
  };

  const handleFollowupStatusChange = (event) => {
    setFollowupStatus(event.target.value);
  };

  let rollId = localStorage.getItem("userId");

  useEffect(() => {
    checklogin();
    fetchCountries();
    infomationLoginUser();
    getAllLeadAssign();
    fetchCountryCode();
    findProfile();
    fetchLeadType();
    fetchLeadSource();
    fetchServiceName();
  }, [rollId]);

  const fetchCountryCode = async () => {
    try {
      const response = await fetch('https://ipinfo.io/json');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data && data.country) {
        setDefaultCountryNumber(data.country);
      } else {
        console.error('Invalid response format:', data);
      }
    } catch (error) {
      console.error('Error fetching country code:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${host}/list-country`);
      if (response.data.error == false) {
        setCountries(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const getState = async (event, state) => {
    const data = {
      country_code: event
    }
    const response = await axios.post(`${host}/find-countrycode`, data);
    console.log(response);
    if (response.data.error == false) {
      if (state == '') {
        setStates(response.data.data);
      } else {
        response.data.data.forEach((val) => {
          if (state == val.short_name) {
            setValue('state', val.short_name)
          }
        })
        setStates(response.data.data);
      }
    }
  };

  const findProfile = async () => {
    const item = {
      _id: localStorage.getItem('userId')
    }
    const response = await axios.post(`${host}/emp-profile`, item);
    console.log(response);
    if (response.data.error == false) {
      setProfile(response.data.data)
    }
  }

  const fetchLeadType = async () => {
    try {
      const response = await axios.get(`${host}/listlead-type`);
      if (response.data.error == false) {
        setLeadTypeList(response.data);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchLeadSource = async () => {
    try {
      const response = await axios.get(`${host}/listlead-source`);
      if (response.data.error == false) {
        setLeadSourceList(response.data);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchServiceName = async () => {
    try {
      const response = await axios.get(`${host}/listservice-name`);
      if (response.data.error == false) {
        setServiceName(response.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  useEffect(() => {
    getAllLeadAssign();
  }, [currentPage, itemsPerPage, searchText, startDate, endDate, totalRows, leadStatus]);

  const getAllLeadAssign = async () => {
    try {
      if (!rollId) {
        console.error("userId not found in localStorage");
        return;
      }
      const requestData = {
        page: currentPage,
        perPage: itemsPerPage,
        searchQuery: searchText,
        startDate: startDate,
        endDate: endDate,
        roleid: rollId,
        leadStatus: leadStatus
      };
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${host}/GetAllLeadSAleRole`,
        headers: {
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      const response = await axios.request(config);
      setTotalRows(response.data.totalRows);
      setData(response.data.data);
      setFilteredData(filterData(response.data.data));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async (_id) => {
    try {
      const id = _id;
      const response = await axios.post(`${host}/GetByIdLeadassign`, {
        id: id,
      });
      setSalesData(response.data);
      setFieldValue("Total_Amount", response.data.data.totalAmount.length == 0 ? 0 : Number(response.data.data.amountDue.pop()))
    } catch (error) {
      console.error(error);
    }
  };

  let validationSchema;
  if (followupStatus == 'Follow') {
    validationSchema = Yup.object({
      Notes_sales: Yup.string().required('Please write the notes'),
      Next_Followup_Date: Yup.string().required('Please select the date')
    });
  } else if (followupStatus == 'Close') {
    validationSchema = Yup.object({
      Notes_sales: Yup.string().required('Please write the notes'),
    });
  } else if (followupStatus == 'Convert') {
    validationSchema = Yup.object({
      Notes_sales: Yup.string().required('Please write the notes'),
      Total_Amount: Yup.string().required('Please enter the total amount'),
      Amount_Paid: Yup.string().required('Please enter the amount paid'),
      Amount_Due: Yup.number().min(0, "Amount Due must be greater than or equal to 0"),
      Upload_Payment_Proof: Yup.string().required('Please select the payement proof'),
    });
  }

  const { handleSubmit: formikSubmit, handleChange, handleBlur, setFieldValue, values, errors: formikErrors, touched, resetForm } = useFormik({
    initialValues: {
      id: "",
      status_convert: followupStatus,
      Followup_Date: "",
      Next_Followup_Date: "",
      Notes_sales: "",
      Total_Amount: "",
      Amount_Paid: "",
      Amount_Due: "",
      Upload_Payment_Proof: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        values.id = selectedId;
        values.status_convert = followupStatus;
        values.Followup_Date = currentDate;
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const response = await axios.post(`${host}/UpdateLead`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data) {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "Lead successfully update.",
          });
          setLoading(false);
          handleClose();
          handleAmountDepositClose();
          getAllLeadAssign();
        }
      } catch (error) {
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: error.message || "An error occurred during lead creation.",
        });
      }
    },
  });

  const userFollowupShow = async (id) => {
    try {
      const response = await axios.post(`${host}/GetByIdLead`, {
        id: id,
      });
      setApiResponse(response.data);
      if (response.data.data.Filing_Process.length > 0) {
        for (let i = response.data.data.Filing_Process.length - 1; i >= 0; i--) {
          const val = response.data.data.Filing_Process[i];
          if (val.caseInitiated == 'Case Initiated') {
            setCaseInitiatedChecked(true);
          }
          if (val.caseInitiated == "true" && val.fileIntiated == 'File Initiated or docs checklist sent') {
            setFileIntiatedChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'Docs received & Pending docs sent') {
            setDocsReceivedChecked(true);
          }
          if (val.caseInitiated == "true" && val.fileIntiated == "true" && val.docsReceived == "true" && val.sopprepration == 'Sop Or letters prepration & Forms prep') {
            setSoppreprationChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'SOP or Letters sent to client') {
            setSopLettersChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'Confirmation received on SOP, letters and docs, Forms confirmation') {
            setConfirmRecievedChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'File sent for Upload or submission') {
            setFilesentChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'Visa approved') {
            setVisaapprovedChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'true' && val.visarefusal == 'Visa refusal') {
            setVisaRefusalChecked(true);
          }
        }
      } else {
        console.log("Not find any filling proccess");
      }
    } catch (error) {
      console.error(error);
    }
    setFollowup(true)
  }

  const followupClose = () => {
    setFollowup(false);
    setCaseInitiatedChecked(false);
    setFileIntiatedChecked(false);
    setDocsReceivedChecked(false);
    setSoppreprationChecked(false);
    setSopLettersChecked(false);
    setConfirmRecievedChecked(false);
    setFilesentChecked(false);
    setVisaapprovedChecked(false);
    setVisaRefusalChecked(false);
  }

  if (apiResponse && apiResponse.data) {
    var followUpStatus = apiResponse.data
    const followUpDates = apiResponse.data.Followup_Date_fiiling_team;
    var followupdatepayment = apiResponse.data.Followup_Date

    const NextFollowupDate = apiResponse.data.Next_Followup_Date_fiiling_team;
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };

    // Format regular follow-up dates
    // var formattedFollowUpDates = followUpDates.map(dateString => {
    //   if (dateString) {
    //     const followUpDate = new Date(dateString);
    //     return followUpDate.toLocaleDateString('en-US', options);
    //   } else {
    //     return null;
    //   }
    // });
    var followupdatepaymentData = followupdatepayment.map(dateStringpayment => {
      if (dateStringpayment) {
        const followUpDatepayment = new Date(dateStringpayment);
        return followUpDatepayment.toLocaleDateString('en-US', options);
      } else {
        return null;
      }
    });
    // Format Next Follow-up Date
    var formattedNextFollowupDate = NextFollowupDate.map(dateStringnext => {
      if (dateStringnext) {
        const followUpDateNext = new Date(dateStringnext);
        return followUpDateNext.toLocaleDateString('en-US', options);
      } else {
        return null;
      }
    });
    var notes = apiResponse.data.Notes_fiiling_team
    var TotalAmount = apiResponse.data.Total_Amount
  }

  let paginatedData = [];
  if (Array.isArray(data)) {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    paginatedData = data.slice(startIndex, endIndex);
  } else {
    console.error("Data is not an array.");
  }

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * itemsPerPage,
      sortable: true,
      width: '100px !important',
    },
    {
      name: "Followup Date",
      selector: (row) => {
        const followupDates = row.Followup_Date;

        if (followupDates && followupDates.length > 0) {
          const originalDate = followupDates[followupDates.length - 1];
          const formattedDate = new Date(originalDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          return formattedDate;
        } else {
          return ''; // Display an empty string if date is not available
        }
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div>
          {row.status_convert[row.status_convert.length - 1] == "Close" ? (
            <button className="btn btn-danger btn-sm p-0 px-1">Close</button>
          ) : row.status_convert[row.status_convert.length - 1] == "Convert" ? (
            <button className="btn btn-success btn-sm p-0 px-1">Convert</button>
          ) : (
            "Pending"
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Followup",
      selector: (row) => {
        return (
          !row.status_convert.includes('Convert') && !row.status_convert.includes('Close') ? (
            <img
              src={followups}
              alt="Profile"
              onClick={() => handleShow(row._id)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            row.status_convert.includes('Convert') && !row.Amount_Due.includes("0") ?
              <img
                src={followups}
                alt="Profile"
                onClick={() => handleAmountDepositShow(row._id)}
                style={{ cursor: "pointer" }}
              /> : ""
          )
        );
      },
      sortable: true,
    },
    {
      name: "Services",
      selector: (row) => <div>{row.Services}</div>,
      sortable: true,
    },
    {
      name: "Lead Date",
      selector: (row) => formatLeadDate(row.createdAt),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <div onClick={() => userFollowupShow(row._id)} style={{ cursor: "pointer" }}>
          <span className="text-info">{`${row.first_name} ${row.last_name}`}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email.",
      selector: (row) => row.email,
      sortable: true,
      width: "250px !important"
    },
    {
      name: "Contact No.",
      selector: (row) => row.contact_number,
      sortable: true,
    },
    {
      name: "Country.",
      selector: (row) => row.country_name,

      sortable: true,
    },
    {
      name: "Sales Executive.",
      selector: (row) => row.Lead_AssignName,
      sortable: true,
    },
    {
      name: "Lead Type.",
      selector: (row) => row.Lead_Type,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      width: '100px !important',
    },
    {
      name: "State",
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: "Education.",
      selector: (row) => row.education,
      sortable: true,
    },
    {
      name: "Notes",
      selector: (row) => <div className="text-info">{row.note}</div>,
      sortable: true,
      width: "250px !important"
    },
  ];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset current page when changing items per page
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAmountDepositShow = (_id) => {
    setFollowupStatus("Convert");
    setSelectedId(_id);
    amountDepositShow(true);
    fetchData(_id);
  };

  const handleAmountDepositClose = () => {
    setFollowupStatus("");
    resetForm();
    setPreview("");
    amountDepositShow(false);
  }

  const toggleOpen = () => {
    reset();
    editId("");
    setLeadModal(true);
  };

  const toggleClose = () => {
    reset();
    setStates([]);
    setLeadModal(false);
    setLoading(false);
  };

  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    setValue('contact_number', formattedValue || value);
  };

  const handleAlternatePhoneInputChange = (value, country, e, formattedValue) => {
    setValue('alt_contact_number', formattedValue || value);
  };

  const handleFileChange = (event) => {
    setValue('Upload_File', event.target.files[0]);
  };

  const handleFilePayementUpload = (event) => {
    // formik.setFieldValue('Upload_Payment_Proof', event.target.files);
  }

  const handleDOBChange = (e) => {
    const dob = e.target.value;
    setValue("DOB", dob);
    const age = calculateAge(dob);
    setValue("age", age);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userId");
  };

  const onSubmit = async (data) => {
    setLoading(true);
    if (id == '') {
      delete data._id;
      const formData = new FormData();
      formData.append('pre_sales_id', getUserIdFromLocalStorage());
      formData.append('Lead_AssignID', getUserIdFromLocalStorage());
      formData.append('Lead_AssignName', `${profile.first_name} ${profile.last_name}`);
      formData.append('status', '2');
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      try {
        const response = await axios.post(`${host}/AddLead`, formData)
        setLoading(false);
        if (response.data.error == true) {
          MySwal.fire({
            icon: "warning",
            title: "Warning!",
            text: `${response.data.message}`,
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          toggleClose();
          getAllLeadAssign();
        }
      } catch (error) {
        setLoading(false);
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while adding the lead. Please try again.",
        });
      }
    } else {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      try {
        const response = await axios.post(`${host}/UpdateLead`, formData)
        setLoading(false);
        if (response.data.error == true) {
          MySwal.fire({
            icon: "warning",
            title: "Warning!",
            text: `${response.data.message}`,
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          toggleClose();
          getAllLeadAssign();
        }
      } catch (error) {
        setLoading(false);
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while adding the lead. Please try again.",
        });
      }
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleStartChange = (e) => {
    const value = e.target.value;
    setStartDate(value ? new Date(value) : '');
  };

  const handleEndChange = (e) => {
    const value = e.target.value;
    setEndDate(value ? new Date(value) : '');
  };

  const formatDate = (date) => {
    if (!date) return ''; // Handle null or undefined date
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleLeadStatusChange = (e) => {
    const selectedValue = e.target.value;
    setLeadStatus(selectedValue);
    // You can perform additional logic here based on the selected lead status
  };

  const formatLeadDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
  };

  const formatLeadTime = (timeString) => {
    const time = new Date(timeString);
    const hours = String(time.getHours()).padStart(2, '0');
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  React.useEffect(() => {
    const totalAmount = parseFloat(values.Total_Amount) || 0;
    const amountPaid = parseFloat(values.Amount_Paid) || 0;
    const amountDue = totalAmount - amountPaid;

    // Update Amount Due in formik.values
    setFieldValue({
      ...values,
      Amount_Due: amountDue
    });
  }, [values.Total_Amount, values.Amount_Paid]);

  const customStyles = {
    table: {
      width: "100%", // or a specific width in pixels or percentage
      backgroundColor: "black", // corrected property name
    },
  };

  const filterData =
    data && data
      ? data.filter((row) => {
        return (
          Object.values(row).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText.toLowerCase())
          )
        );
      })
      : [];

  const proficiencyTestValue = watch('Proficiency_Test');

  const handleTotalAmount = (e) => {
    setFieldValue("Total_Amount", e.target.value)
    setFieldValue("Amount_Due", e.target.value - values.Amount_Paid);
  }

  const handleAmountPaid = (e) => {
    setFieldValue("Amount_Paid", e.target.value);
    setFieldValue("Amount_Due", values.Total_Amount - e.target.value);
  }

  const isWeekendDay = (date) => {
    return isWeekend(date);
  }

  const filterWeekends = (date) => {
    return !isWeekendDay(date);
  }

  const handleDateTime = (dateTime) => {
    setFieldValue("Next_Followup_Date", dateTime);
  }

  return (
    <>
      <Sidebar />
      <CustomNavbar />
      <div className="main-content">
        <div className="container-fluid">
          <div className="topbox">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="left-logo">
                  <div>
                    <img src={profile2} alt="Logo" className="logo" />
                  </div>
                  <p className="topboxttex">Lead Management</p>
                </div>
              </div>
              {/* <div className="right-logo"> */}
              <div className="col-md-4 ">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Leads
                    </li>
                  </ol>
                </nav>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className="row pt-3 my-3">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3 form-groupcustom">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    From
                  </label>
                  <input
                    type="date"
                    value={formatDate(startDate)}
                    onChange={handleStartChange}
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="col-md-3 form-groupcustom ">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    To
                  </label>
                  <input
                    type="date"
                    value={formatDate(endDate)}
                    onChange={handleEndChange}
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="col-md-3 form-groupcustom">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    Lead Status
                  </label>
                  <select
                    className="form-select form-select-sm"
                    aria-label="Default select example"
                    value={leadStatus}
                    onChange={handleLeadStatusChange}
                  >
                    <option value=" " selected>Lead Status</option>
                    <option value="Pending">Pending</option>
                    <option value="Close">Close</option>
                    <option value="Convert">Convert</option>
                  </select>
                </div>
                {/* <div className="col-md-3 mt-4">
                  <div>
                    <button
                      type="submit"
                      className="buttonAdd"
                      fdprocessedid="hprgo"
                    >
                      Submit
                    </button>
                  </div>
                </div> */}
                <div className="col-md-12 ">
                  <div className="d-flex justify-content-between  me-2">
                    <div className="wrap">
                      <div className="search">
                        <input
                          type="text"
                          value={searchText}
                          onChange={handleSearch}
                          placeholder="Search"
                        />
                        {/* <button type="submit" className="searchButton">
                      Search
                    </button> */}
                      </div>
                    </div>

                    <button className="buttonAdd" onClick={toggleOpen}>
                      Lead Add
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="row">
                {/* <div
                  id="list-view"
                  className="tab-pane fade active show col-lg-12"
                > */}
                <div className="card">
                  <div className="card-header">
                    <DataTable
                      className="custom-data-table"
                      columns={columns}
                      data={data}
                    />
                    {/* Pagination */}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <span>Rows per page:</span>
                      <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                      <span>{`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage)} of ${totalRows}`}</span>
                      <div>
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                          Previous Page
                        </button>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={((currentPage - 1) * itemsPerPage + 1) >= totalRows}>
                          Next Page
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <Offcanvas
          className="LeadBox scrolldiv"
          show={show}
          onHide={handleClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Followup</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form onSubmit={formikSubmit}>
              <div className="modal-body modalpadding">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Followup Status</label>
                    <br />
                    <input
                      className="form-control"
                      type="hidden"
                      id="id"
                      value={values.selectedId}
                      name="id"
                    />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Follow"
                        id="Follow"
                        defaultValue="Follow"
                        checked={followupStatus == "Follow"}
                        onChange={handleFollowupStatusChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Follow Up
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Close"
                        id="Close"
                        defaultValue="Close"
                        checked={followupStatus == "Close"}
                        onChange={handleFollowupStatusChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Close
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Convert"
                        id="Convert"
                        defaultValue="Convert"
                        checked={followupStatus == "Convert"}
                        onChange={handleFollowupStatusChange}
                      />
                      <label className="form-check-label" htmlFor="Convert">
                        Convert
                      </label>
                    </div>
                    {touched.status_convert &&
                      formikErrors.status_convert && (
                        <div className="error-message">
                          {formikErrors.status_convert}
                        </div>
                      )}
                  </div>
                  {followupStatus == "Follow" && (
                    <>
                      <div className="form-group col-md-6">
                        <label>Followup Date</label>
                        <br />
                        <input
                          type="datetime"
                          name="Followup_Date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={currentDate}
                          className="form-control"
                          readOnly // Make the input read-only
                        />

                      </div>

                      <div className="form-group col-md-12">
                        <label>Notes</label>
                        <br />
                        <div className="form-floating">
                          <textarea
                            className={`form-control ${touched.Notes_sales && formikErrors.Notes_sales ? 'is-invalid' : ''
                              }`}
                            placeholder="Leave a comment here"
                            id="floatingTextarea"
                            name="Notes_sales"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Notes_sales}
                          ></textarea>
                          <label htmlFor="floatingTextarea">Notes</label>
                        </div>
                        {touched.Notes_sales &&
                          formikErrors.Notes_sales && (
                            <div className="error-message">
                              {formikErrors.Notes_sales}
                            </div>
                          )}
                      </div>

                      <div className="form-group col-md-6 my-3">
                        <label>Next Followup Date</label>
                        <br />
                        <DatePicker
                          className="form-control"
                          dateFormat="dd-MM-yyyy HH:mm"
                          showTimeSelect
                          timeIntervals={30}
                          timeFormat="HH:mm"
                          name="Next_Followup_Date"
                          selected={values.Next_Followup_Date} // Use selected instead of value
                          onChange={handleDateTime}
                          onBlur={handleBlur}
                        />
                        {touched.Next_Followup_Date &&
                          formikErrors.Next_Followup_Date && (
                            <div className="error-message">
                              {formikErrors.Next_Followup_Date}
                            </div>
                          )}
                      </div>
                    </>
                  )}
                  {followupStatus === "Close" && (
                    <>
                      <div className="form-group col-md-6">
                        <label>Followup Date</label>
                        <br />
                        <input
                          className="form-check-input"
                          type="hidden"
                          id="id"
                          value={values.selectedId}
                          name="id"
                        />
                        <input
                          type="datetime"
                          name="Followup_Date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={currentDate}
                          className="form-control"
                          readOnly
                        />
                        {touched.Followup_Date &&
                          formikErrors.Followup_Date && (
                            <div className="error-message">
                              {formikErrors.Followup_Date}
                            </div>
                          )}
                      </div>

                      <div className="form-group col-md-12">
                        <label>Notes</label>
                        <br />
                        <div className="form-floating">
                          <textarea
                            className={`form-control ${touched.Notes_sales && formikErrors.Notes_sales ? 'is-invalid' : ''
                              }`}
                            placeholder="Leave a comment here"
                            id="floatingTextarea"
                            name="Notes_sales"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Notes_sales}
                          ></textarea>
                          <label htmlFor="floatingTextarea">Notes</label>
                        </div>
                        {touched.Notes_sales &&
                          formikErrors.Notes_sales && (
                            <div className="error-message">
                              {formikErrors.Notes_sales}
                            </div>
                          )}
                      </div>
                    </>
                  )}
                  {followupStatus == "Convert" && (
                    <>
                      <div className="form-group col-md-6">
                        <label>Followup Date</label>
                        <br />
                        <input
                          className="form-check-input"
                          type="hidden"
                          id="id"
                          value={values.selectedId}
                          name="id"
                        />
                        <input
                          type="datetime"
                          name="Followup_Date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={currentDate}
                          className="form-control"
                          readOnly
                        />
                        {touched.Followup_Date &&
                          formikErrors.Followup_Date && (
                            <div className="error-message">
                              {formikErrors.Followup_Date}
                            </div>
                          )}
                      </div>

                      <div className="form-group col-md-12">
                        <label>Notes</label>
                        <br />
                        <div className="form-floating">
                          <textarea
                            className={`form-control ${touched.Notes_sales && formikErrors.Notes_sales ? 'is-invalid' : ''
                              }`}
                            placeholder="Leave a comment here"
                            id="floatingTextarea"
                            name="Notes_sales"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Notes_sales}
                          ></textarea>
                          <label htmlFor="floatingTextarea">Notes</label>
                        </div>
                        {touched.Notes_sales &&
                          formikErrors.Notes_sales && (
                            <div className="error-message">
                              {formikErrors.Notes_sales}
                            </div>
                          )}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Total Amount</label>
                        <br />
                        <input
                          type="number"
                          min={0}
                          name="Total_Amount"
                          onChange={handleTotalAmount}
                          onBlur={handleBlur}
                          value={values.Total_Amount}
                          className="form-control"
                        />
                        {touched.Total_Amount && formikErrors.Total_Amount && (
                          <div className="error-message">{formikErrors.Total_Amount}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Amount Paid</label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          name="Amount_Paid"
                          onChange={handleAmountPaid}
                          onBlur={handleBlur}
                          value={values.Amount_Paid}
                          className="form-control"
                        />
                        {touched.Amount_Paid && formikErrors.Amount_Paid && (
                          <div className="error-message">{formikErrors.Amount_Paid}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Amount Due</label>
                        <br />
                        <input
                          type="text"
                          name="Amount_Due"
                          value={values.Amount_Due == '' ? 0 : values.Total_Amount - values.Amount_Paid}
                          className="form-control"
                          min={0}
                          readOnly // Make it read-only
                        />
                        {touched.Amount_Due && formikErrors.Amount_Due && (
                          <div className="error-message">{formikErrors.Amount_Due}</div>
                        )}
                      </div>

                      <div className="form-group col-md-8">
                        <label>Upload Payment Proof</label>
                        <br />
                        <input
                          type="file"
                          accept=".png,.jpg,.jpeg,.pdf"
                          name="Upload_Payment_Proof"
                          onChange={(event) => {
                            let reader = new FileReader();
                            reader.onload = () => {
                              if (reader.readyState === 2) {
                                setFieldValue("Upload_Payment_Proof", event.target.files[0]);
                                setPreview(reader.result)
                              }
                            }
                            reader.readAsDataURL(event.target.files[0])
                          }}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                        {touched.Upload_Payment_Proof &&
                          formikErrors.Upload_Payment_Proof && (
                            <div className="error-message">
                              {formikErrors.Upload_Payment_Proof}
                            </div>
                          )}
                      </div>
                      <img src={preview} width="200" alt="" />
                    </>
                  )}
                </div>
              </div>

              <div className="text-center my-3">
                {loading ? (
                  <button className="btn btn-primary me-2">
                    Loading...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary me-2 save">
                    Save
                  </button>
                )}
                <button
                  type="reset"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>

            {followupStatus == "Follow" && (
              <>
                <div className="col-md-12">
                  <div className="table-responsive tablebg">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" className="custom-th">Followup Status</th>
                          <th scope="col" className="custom-th">Followup Date</th>
                          <th scope="col" className="custom-th">Notes</th>
                          <th scope="col" className="custom-th">Next Followup Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {salesData.data && ( // Check if salesData.data exists
                          <tr>
                            <td style={{ border: '1px solid #000' }}>
                              {salesData.data.status_convert.slice().reverse().map(
                                (val, index) => (
                                  <div key={index}>{val}</div>
                                )
                              )}
                            </td>
                            <td style={{ border: '1px solid #000' }}>
                              {salesData.data.followupDate.slice().reverse().map((val, index) => (
                                <div key={index}>{formatLeadDate(val)} {formatLeadTime(val)}</div>
                              ))}
                            </td>
                            <td style={{ border: '1px solid #000' }}>
                              {salesData.data.notesSales.slice().reverse().map((val, index) => (
                                <div key={index}>{val}</div>
                              ))}
                            </td>
                            <td style={{ border: '1px solid #000' }}>
                              {salesData.data.Next_Followup_Date.slice().reverse().map(
                                (val, index) => (
                                  <div key={index}>{formatLeadDate(val)}</div>
                                )
                              )}
                            </td>
                          </tr>
                        )} */}
                        {salesData && salesData.data && salesData.data.status_convert && salesData.data.status_convert.length > 0 && (
                          salesData.data.status_convert.slice().reverse().map((status, index) => (
                            <tr key={index}>
                              <td style={{ border: '1px solid #000' }}>{status}</td>
                              <td style={{ border: '1px solid #000' }}>
                                {formatLeadDate(salesData.data.followupDate.slice().reverse()[index])} {formatLeadTime(salesData.data.followupDate.slice().reverse()[index])}
                              </td>
                              <td style={{ border: '1px solid #000' }}>{salesData.data.notesSales.slice().reverse()[index]}</td>
                              <td style={{ border: '1px solid #000' }}>{formatLeadDate(salesData.data.Next_Followup_Date.slice().reverse()[index])}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>

      <>
        <Offcanvas
          className="LeadBox scrolldiv"
          show={amountDeposit}
          onHide={handleAmountDepositClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Followup</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form onSubmit={formikSubmit}>
              <div className="modal-body modalpadding">
                <div className="row">
                  <input
                    className="form-control"
                    type="hidden"
                    id="id"
                    value={values.selectedId}
                    name="id"
                  />

                  <>
                    <div className="form-group col-md-6">
                      <label>Followup Date</label>
                      <br />
                      <input
                        type="datetime"
                        name="Followup_Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={currentDate}
                        className="form-control"
                        readOnly
                      />
                      {touched.Followup_Date &&
                        formikErrors.Followup_Date && (
                          <div className="error-message">
                            {formikErrors.Followup_Date}
                          </div>
                        )}
                    </div>

                    <div className="form-group col-md-12">
                      <label>Notes</label>
                      <br />
                      <div className="form-floating">
                        <textarea
                          className={`form-control ${touched.Notes_sales && formikErrors.Notes_sales ? 'is-invalid' : ''
                            }`}
                          placeholder="Leave a comment here"
                          id="floatingTextarea"
                          name="Notes_sales"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Notes_sales}
                        ></textarea>
                        <label htmlFor="floatingTextarea">Notes</label>
                      </div>
                      {touched.Notes_sales &&
                        formikErrors.Notes_sales && (
                          <div className="error-message">
                            {formikErrors.Notes_sales}
                          </div>
                        )}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Total Amount</label>
                      <br />
                      <input
                        type="text"
                        min={0}
                        name="Total_Amount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Total_Amount}
                        className="form-control"
                        readOnly
                      />
                      {touched.Total_Amount && formikErrors.Total_Amount && (
                        <div className="error-message">{formikErrors.Total_Amount}</div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Amount Paid</label>
                      <br />
                      <input
                        type="number"
                        min={1}
                        name="Amount_Paid"
                        onChange={handleAmountPaid}
                        onBlur={handleBlur}
                        value={values.Amount_Paid}
                        className="form-control"
                      />
                      {touched.Amount_Paid && formikErrors.Amount_Paid && (
                        <div className="error-message">{formikErrors.Amount_Paid}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Amount Due</label>
                      <br />
                      <input
                        type="text"
                        name="Amount_Due"
                        value={values.Amount_Due == '' ? 0 : values.Total_Amount - values.Amount_Paid}
                        className="form-control"
                        min={0}
                        readOnly // Make it read-only
                      />
                      {touched.Amount_Due && formikErrors.Amount_Due && (
                        <div className="error-message">{formikErrors.Amount_Due}</div>
                      )}
                    </div>

                    <div className="form-group col-md-8">
                      <label>Upload Payment Proof</label>
                      <br />
                      <input
                        type="file"
                        accept=".png,.jpg,.jpeg,.pdf"
                        name="Upload_Payment_Proof"
                        onChange={(event) => {
                          let reader = new FileReader();
                          reader.onload = () => {
                            if (reader.readyState === 2) {
                              setFieldValue("Upload_Payment_Proof", event.target.files[0]);
                              setPreview(reader.result)
                            }
                          }
                          reader.readAsDataURL(event.target.files[0])
                        }}
                        onBlur={handleBlur}
                        className="form-control"
                      />
                      {touched.Upload_Payment_Proof &&
                        formikErrors.Upload_Payment_Proof && (
                          <div className="error-message">
                            {formikErrors.Upload_Payment_Proof}
                          </div>
                        )}
                    </div>
                    <img src={preview} width="200" alt="" />
                  </>
                </div>
              </div>

              <div className="text-center my-3">
                {loading ? (
                  <button className="btn btn-primary me-2 save">
                    Loading...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary me-2 save">
                    Save
                  </button>
                )}
                <button
                  type="reset"
                  className="btn btn-secondary"
                  onClick={handleAmountDepositClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Offcanvas.Body>
        </Offcanvas>
      </>

      <>
        <Offcanvas
          className="LeadBox scrolldiv"
          show={leadModal}
          onHide={toggleClose}
          placement={"end"}
        >
          <div>
            <Offcanvas.Header closeButton>
              {id == "" ? (
                <Offcanvas.Title bsPrefix="titlestl">Add Lead</Offcanvas.Title>
              ) : (
                <Offcanvas.Title bsPrefix="titlestl">Update Lead</Offcanvas.Title>
              )}
            </Offcanvas.Header>
            <Offcanvas.Body bsPrefix="offcanvaspadingbody">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body modalpadding">
                  <div className="row">
                    <div className="col-md-12 mb-2 bg-color">
                      Basic Information
                    </div>
                    <input type="hidden" name="_id"  {...register('_id')} />
                    <div className="col-md-12">
                      <label>Name</label>
                    </div>
                    <div className="form-group col-md-4">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="prefix"
                        {...register('prefix', { required: 'Please select a Name' })}
                      >
                        <option value="">Select</option>
                        <option value="Mr.">Mr. </option>
                        <option value="Ms.">Ms.</option>
                        <option value="Mrs.">Mrs.</option>
                      </select>
                      {errors.prefix && (
                        <div className="error-message">
                          {errors.prefix.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-4">
                      <input
                        type="text"
                        placeholder="First name"
                        className="form-control"
                        name="first_name"
                        {...register('first_name', { required: 'Please enter first name' })}

                      />
                      {errors.first_name && (
                        <div className="error-message">
                          {errors.first_name.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-4">
                      <input
                        type="text"
                        placeholder="Last name"
                        className="form-control"
                        name="last_name"
                        {...register('last_name', { required: 'Please enter last name' })}

                      />
                      {errors.last_name && (
                        <div className="error-message">
                          {errors.last_name.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label>Gender</label>
                      <br />
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="gender"
                        {...register('gender', { required: 'Please select gender' })}
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                      {errors.gender && (
                        <div className="error-message">
                          {errors.gender.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-4">
                      <label>Date of birth</label>
                      <br />
                      <input
                        type="date"
                        name="DOB"
                        className={`form-control ${errors.DOB ? 'is-invalid' : ''}`}
                        onChange={(e) => {
                          register('DOB', {
                            max: {
                              value: '2015-12-31',
                              message: 'Date must be on or before 2015-12-31',
                            },
                          });
                          handleDOBChange(e);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <label>Age</label>
                      <br />
                      <input
                        type="text"
                        name="age"
                        className="form-control"
                        {...register('age')}
                        disabled
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label>Email</label>
                      <br />
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        {...register('email', { required: 'Please enter the email' })}
                      />
                      {errors.email && (
                        <div className="error-message">
                          {errors.email.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Education</label>
                      <br />
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="education"
                        {...register('education', { required: 'Please select the education' })}
                      >
                        <option value="">Select</option>
                        <option value="10 TH">10 TH</option>
                        <option value="12 TH">12 TH</option>
                        <option value="Gradute">Gradute</option>
                        <option value="Diploma">Diploma</option>
                        <option value="Post Gradute">Post Gradute</option>
                        <option value="Post Gradute and Above">Post Gradute and Above</option>

                      </select>
                      {errors.education && (
                        <div className="error-message">
                          {errors.education.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Contact No.</label>
                      <br />
                      <PhoneInput
                        country={'in'}
                        onChange={handlePhoneInputChange}
                        inputProps={{
                          name: 'contact_number',
                          onBlur: () => register('contact_number', { required: 'Please enter the contact number' }), // onBlur registration
                          className: `form-control ${errors.contact_number ? 'is-invalid' : ''}`,
                        }}
                      />
                      {errors.contact_number && (
                        <div className="error-message">{errors.contact_number.message}</div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label> Alt Contact No.</label>
                      <br />
                      <PhoneInput
                        country={'in'}
                        onChange={handleAlternatePhoneInputChange}
                        inputProps={{
                          name: 'alt_contact_number',
                          onBlur: () => register('alt_contact_number'), // onBlur registration
                          className: `form-control ${errors.alt_contact_number ? 'is-invalid' : ''}`,
                        }}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label>Country</label>
                      <br />
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="country"
                        {...register('country', { required: 'Please select the country' })}
                        onChange={(event) => getState(event.target.value)}
                      >
                        <option value="">
                          Select
                        </option>
                        {countries.map((country) => (
                          <option value={country.short_name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                      {errors.country && (
                        <div className="error-message">
                          {errors.country.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label>State</label>
                      <br />
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="state"
                        {...register('state', { required: 'Please select the state' })}
                      >
                        <option value="">
                          Select
                        </option>
                        {states.map((state) => (
                          <option value={state.short_name}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                      {errors.state && (
                        <div className="error-message">
                          {errors.state.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-12 my-3 bg-color">
                      Lead Information
                    </div>

                    <div className="form-group col-md-12">
                      <label>Notes</label>
                      <br />
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea"
                          name="note"
                          {...register('note', { required: 'Please enter the note' })}
                        ></textarea>
                        <label htmlFor="floatingTextarea">Notes</label>
                      </div>
                      {errors.note && (
                        <div className="error-message">
                          {errors.note.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label>Upload File</label>
                      <br />
                      <input
                        type="file"
                        className="form-control"
                        name="Upload_File" // Add a name for the file input if needed
                        onChange={handleFileChange}
                        accept=".jpg, .png, .jpeg"
                      />
                    </div>

                    <div className="form-group col-md-6"></div>

                    <div className="form-group col-md-6">
                      <label>Have you taken Any Proficiency Test? </label>
                      <br />
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="Proficiency_Test"
                        {...register('Proficiency_Test', { required: 'Please select the have you taken Any Proficiency Test?' })}
                      >
                        <option value="" >
                          Select
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                      {errors.Proficiency_Test && (
                        <div className="error-message">
                          {errors.Proficiency_Test.message}
                        </div>
                      )}
                    </div>

                    {proficiencyTestValue == "yes" && (
                      <>
                        <div className="form-group col-md-6">
                          <label>If Yes?</label>
                          <br />
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="proficiencyLevel"
                            {...register('proficiencyLevel', { required: 'Please select proficiency level' })}
                          >
                            <option value="" disabled>
                              Select proficiency level
                            </option>
                            <option value="IELTS">IELTS</option>
                            <option value="IELTS ACEDEMIC">IELTS ACEDEMIC</option>
                            <option value="IELTS GENERAL">IELTS GENERAL</option>
                            <option value="PTE">PTE</option>
                            <option value="DUOLINGO">DUOLINGO</option>
                            <option value="CELPIP">CELPIP</option>
                            <option value="PTE CORE">PTE CORE</option>
                          </select>
                          {errors.proficiencyLevel && (
                            <div className="error-message">{errors.proficiencyLevel.message}</div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label>Reading (R) </label>
                          <br />
                          <input
                            type="number"
                            min={1}
                            max={200}
                            step="0.01" // Allows up to two decimal places
                            pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                            name="Reading_score"
                            {...register('Reading_score', {
                              required: 'Please enter Reading score', min: { value: 1, message: 'Value must be at least 1' },
                              max: { value: 200, message: 'Value must be at most 200' }
                            })}
                            className="form-control"
                          />
                          {errors.Reading_score && (
                            <div className="error-message">{errors.Reading_score.message}</div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label>Writing (W) </label>
                          <br />
                          <input
                            type="number"
                            min={1}
                            max={200}
                            step="0.01" // Allows up to two decimal places
                            pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                            name="Writing_score"
                            {...register('Writing_score', {
                              required: 'Please enter Writing score', min: { value: 1, message: 'Value must be at least 1' },
                              max: { value: 200, message: 'Value must be at most 200' }
                            })}
                            className="form-control"
                          />
                          {errors.Writing_score && (
                            <div className="error-message">{errors.Writing_score.message}</div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label>Listening (L)</label>
                          <br />
                          <input
                            type="number"
                            min={1}
                            max={200}
                            step="0.01" // Allows up to two decimal places
                            pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                            name="Listening_score"
                            {...register('Listening_score', {
                              required: 'Please enter Listening score', min: { value: 1, message: 'Value must be at least 1' },
                              max: { value: 200, message: 'Value must be at most 200' }
                            })}
                            className="form-control"
                          />
                          {errors.Listening_score && (
                            <div className="error-message">{errors.Listening_score.message}</div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label>Speaking (S)</label>
                          <br />
                          <input
                            type="number"
                            min={1}
                            max={200}
                            step="0.01" // Allows up to two decimal places
                            pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                            name="Speaking_score"
                            {...register('Speaking_score', {
                              required: 'Please enter speaking', min: { value: 1, message: 'Value must be at least 1' },
                              max: { value: 200, message: 'Value must be at most 200' }
                            })}
                            className="form-control"
                          />
                          {errors.Speaking_score && (
                            <div className="error-message">{errors.Speaking_score.message}</div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label>Over All</label>
                          <br />
                          <input
                            type="number"
                            min={1}
                            step="0.01" // Allows up to two decimal places
                            pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                            name="over_all"
                            {...register('over_all', {
                              required: 'Please enter over all', min: { value: 1, message: 'Value must be at least 1' }
                            })}
                            className="form-control"
                          />
                          {errors.over_all && (
                            <div className="error-message">{errors.over_all.message}</div>
                          )}
                        </div>
                      </>
                    )}

                    <div className="form-group col-md-4">
                      <label>Lead Type</label>
                      <br />
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="Lead_Type"
                        {...register('Lead_Type', { required: 'Please select lead type' })}
                      >
                        <option value="">Select</option>
                        {leadTypeList.data && leadTypeList.data.length > 0 ? (
                          leadTypeList.data.map((val, key) => (
                            <option value={val.lead_type}>{val.lead_type}</option>
                          ))
                        ) : null}
                      </select>
                      {errors.Lead_Type && (
                        <div className="error-message">{errors.Lead_Type.message}</div>
                      )}
                    </div>

                    <div className="form-group col-md-4">
                      <label>Lead Source</label>
                      <br />
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="Lead_Source"
                        {...register('Lead_Source', { required: 'Please select lead source' })}
                      >
                        <option value="">Select</option>
                        {leadSourceList.data && leadSourceList.data.length > 0 ? (
                          leadSourceList.data.map((val, key) => (
                            <option value={val.lead_source}>{val.lead_source}</option>
                          ))
                        ) : null}
                      </select>
                      {errors.Lead_Source && (
                        <div className="error-message">{errors.Lead_Source.message}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {id == "" ? (
                    loading ? (
                      <button className="btn btn-primary me-2">
                        Loading...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary me-2">
                        Save
                      </button>
                    )
                  ) : (
                    loading ? (
                      <button className="btn btn-primary me-2">
                        Loading...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary me-2">
                        Update
                      </button>
                    )
                  )}
                  <button type="reset" onClick={toggleClose} className="btn btn-secondary">
                    Cancel
                  </button>
                </div>
              </form>
            </Offcanvas.Body>
          </div>
        </Offcanvas>
      </>

      <>
        <Offcanvas
          className="profileCanvas"
          show={followup}
          onHide={followupClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {apiResponse && apiResponse.data && (
                <div className="profilFlex">
                  <div className="prbg">
                    <i className="fa-solid fa-user" />
                  </div>
                  <h6 className="prftitle">{apiResponse.data.first_name}  {apiResponse.data.last_name} </h6>
                </div>
              )}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form action="">
              {apiResponse && apiResponse.data && (
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Name</h6>
                        <p>{apiResponse.data.first_name}</p>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                    <div className="namedetail">
                      <h6>Name</h6>
                      <p>Areon Foster</p>
                    </div>
                  </div> */}
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Gender</h6>
                        <p>{apiResponse.data.gender}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Email</h6>
                        <p>{apiResponse.data.email}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Age</h6>
                        <p>{apiResponse.data.age == "undefined" ? "" : apiResponse.data.age}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Contact No.</h6>
                        <p>{apiResponse.data.contact_number}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Alt. Contact No. </h6>
                        <p>{apiResponse.data.alt_contact_number}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Country</h6>
                        <p>{apiResponse.data.country_name}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>State</h6>
                        <p>{apiResponse.data.state_name}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Education</h6>
                        <p>{apiResponse.data.education}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Note</h6>
                        <p>{apiResponse.data.note}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Services</h6>
                        <p>{apiResponse.data.Services}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Upload File</h6>
                        {apiResponse.data.Upload_File == null ? "" : <img src={`https://api.checkcheckservices.in/public/${apiResponse.data.Upload_File}`} style={{ width: "150px" }} />}
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="rowv mt-2">
                    <div className="col-md-12">
                      <div className="mytabpill">
                        <ul
                          className="nav nav-pills nav-justified my-2"
                          id="myTabs"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="commentary-tab"
                              data-bs-toggle="pill"
                              href="#Commentary"
                              role="tab"
                            >
                              Details
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="videos-tab"
                              data-bs-toggle="pill"
                              href="#Videos"
                              role="tab"
                            >
                              Follow-ups
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="events-tab"
                              data-bs-toggle="pill"
                              href="#Events"
                              role="tab"
                            >
                              Payment
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="filing-tab"
                              data-bs-toggle="pill"
                              href="#Filing"
                              role="tab"
                            >
                              Filing Process
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane fade show active"
                            id="Commentary"
                            role="tabpanel"
                          >
                            <div className="row">
                              <div className="col-md-12 my-3">
                                <div className="accrordiondiv">
                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          Lead Information
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body">
                                          <table className="table">
                                            <tbody>
                                              <tr>
                                                <th>Lead Status</th>
                                                <td>{apiResponse.data.status_convert[apiResponse.data.status_convert.length - 1]}</td>
                                              </tr>

                                              <tr>
                                                <th>Lead Type</th>
                                                <td>{apiResponse.data.Lead_Type}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Source</th>
                                                <td>{apiResponse.data.Lead_Source}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Assign Sales</th>
                                                <td>{apiResponse.data.Lead_AssignName == null ? "N/A" : apiResponse.data.Lead_AssignName}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Assign Filling Manager</th>
                                                <td>{apiResponse.data.Filing_ManagerName == null ? "N/A" : apiResponse.data.Filing_ManagerName}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Assign Filling Team</th>
                                                <td>{apiResponse.data.Filing_TeamName == null ? "N/A" : apiResponse.data.Filing_TeamName}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="Videos"
                            role="tabpanel"
                          >
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="table-responsive tablebg">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <th>Followup Status</th>
                                        <th>Follow up Date</th>
                                        <th>Note</th>
                                        <th>Next Follow up Date</th>
                                      </tr>
                                      {followUpStatus.Followup_Date_fiiling_team.slice().reverse().map((date, index) => (
                                        <tr key={index}>
                                          <td>{followUpStatus.Filing_Team_Status[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
                                          <td>{formatLeadDate(date)} {formatLeadTime(date)}</td>
                                          <td>{followUpStatus.Notes_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
                                          <td>{followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]) : ""}</td>
                                        </tr>
                                      ))}
                                      {followUpStatus.Followup_Date.slice().reverse().map((formattedDate, index) => (
                                        <tr key={index}>
                                          <td>{followUpStatus.status_convert[followUpStatus.Followup_Date.length - 1 - index]}</td>
                                          <td>{formatLeadDate(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])} {formatLeadTime(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])}</td>
                                          <td>{followUpStatus.Notes_sales[followUpStatus.Followup_Date.length - 1 - index]}</td>
                                          <td>{followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index]) : ""}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="Events"
                            role="tabpanel"
                          >
                            <div className="row justify-content-center">
                              <div className="col-md-3">
                                <div className="namedetail">
                                  <h6>Total Payment</h6>
                                  {apiResponse.data.status_convert.includes('Convert') ? (
                                    <p>{followUpStatus.Total_Amount.slice().reverse().map((formattedDate, index) => (
                                      <tr key={index}>
                                        <td>${formattedDate}</td>
                                      </tr>
                                    ))}</p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="namedetail">
                                  <h6>Payment Received</h6>
                                  {apiResponse.data.status_convert.includes('Convert') ? (
                                    <p>{followUpStatus.Amount_Paid.slice().reverse().map((formattedDate, index) => (
                                      <tr key={index}>
                                        <td>${formattedDate}</td>
                                      </tr>
                                    ))}</p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="namedetail">
                                  <h6>Remaining Payment</h6>
                                  {apiResponse.data.status_convert.includes('Convert') ? (
                                    <p>{followUpStatus.Amount_Due.slice().reverse().map((formattedDate, index) => (
                                      <tr key={index}>
                                        <td>${formattedDate}</td>
                                      </tr>
                                    ))}</p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="table-responsive tablebg">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Proof</th>
                                      </tr>
                                      {apiResponse.data.status_convert.includes('Convert') ? (
                                        followUpStatus.Payment_Proof_Date.slice().reverse().map((formattedDate, index) => (
                                          <tr key={index}>
                                            <td>{formatLeadDate(formattedDate)}</td>
                                            <td>{followUpStatus.Amount_Paid[followUpStatus.Payment_Proof_Date.length - 1 - index]}</td>
                                            <td>
                                              <a href={`https://api.checkcheckservices.in/public/${followUpStatus.Upload_Payment_Proof[followUpStatus.Payment_Proof_Date.length - 1 - index]}`} download={`PaymentProof-${index}`} target="_blank" >
                                                View
                                              </a>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <p></p>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="Filing"
                            role="tabpanel"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12 my-3">
                                  <div className="accrordiondiv">
                                    <div
                                      className="accordion"
                                      id="accordionExample2"
                                    >
                                      <div className="accordion-item">
                                        <h2
                                          className="accordion-header"
                                          id="headingOne"
                                        >
                                          <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                          >
                                            Services
                                          </button>
                                        </h2>
                                        <div
                                          id="collapseOne"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="headingOne"
                                          data-bs-parent="#accordionExample2"
                                        >
                                          <div className="accordion-body">
                                            {serviceName.data && serviceName.data.length > 0 ? (
                                              serviceName.data.map((val, key) => (
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    checked={apiResponse.data.Services === val.service_name}

                                                  />
                                                  <label
                                                    className="form-check-label forcheckfont"
                                                    htmlfor="flexRadioDefault1"
                                                  >
                                                    {val.service_name}
                                                  </label>
                                                </div>
                                              ))
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="accordion-item">
                                        <h2
                                          className="accordion-header"
                                          id="headingTwo"
                                        >
                                          <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                          >
                                            Filing Status
                                          </button>
                                        </h2>
                                        <div
                                          id="collapseTwo"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="headingTwo"
                                          data-bs-parent="#accordionExample2"
                                        >
                                          <div className="accordion-body accrfp">
                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="caseInitiated"
                                                checked={caseInitiatedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>Case Initiated</h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="fileIntiated"
                                                checked={fileInitiatedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  File Initiated or docs checklist
                                                  sent
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="docsReceived"
                                                checked={docReceivedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  Docs received & Pending docs
                                                  sent{" "}
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="sopprepration"
                                                checked={soppreprationChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  Sop Or letters prepration &
                                                  Forms prep
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="sopletters"
                                                checked={sopLetterChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  SOP or Letters sent to client{" "}
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="confirmrecieved"
                                                checked={confirmReceivedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  Confirmation received on SOP,
                                                  letters and docs, Forms
                                                  confirmation{" "}
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="filesent"
                                                checked={fileSentChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  File sent for Upload or
                                                  submission
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="visaapproved"
                                                checked={visaApprovedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>Visa approved</h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="visarefusal"
                                                checked={visaRefusalChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>Visa refusal</h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </Offcanvas.Body>
        </Offcanvas>
      </>

    </>
  );
}
