import React, { useState, useEffect } from 'react';
import Offcanvas from "react-bootstrap/Offcanvas";
import withReactContent from "sweetalert2-react-content";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useForm } from 'react-hook-form';
import axios from "axios";
import Swal from "sweetalert2";
import host from "./utils";

const Lead = () => {

    const [defaultCountryNumber, setDefaultCountryNumber] = useState('in');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const { register, handleSubmit, watch, formState: { errors }, setValue, reset } = useForm();
    const [errorMessage, setErrorMessage] = useState('');
    const [leadTypeList, setLeadTypeList] = useState([]);
    const [leadSourceList, setLeadSourceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        fetchCountryCode();
        fetchCountries();
        fetchLeadType();
        fetchLeadSource();
    }, []);

    const fetchCountryCode = async () => {
        try {
            const response = await fetch('https://ipinfo.io/json');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data && data.country) {
                setDefaultCountryNumber(data.country);
            } else {
                console.error('Invalid response format:', data);
            }
        } catch (error) {
            console.error('Error fetching country code:', error);
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await axios.get(`${host}/list-country`);
            if (response.data.error == false) {
                setCountries(response.data.data)
            }
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchLeadType = async () => {
        try {
            const response = await axios.get(`${host}/listlead-type`);
            if (response.data.error == false) {
                setLeadTypeList(response.data);
            }
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchLeadSource = async () => {
        try {
            const response = await axios.get(`${host}/listlead-source`);
            if (response.data.error == false) {
                setLeadSourceList(response.data);
            }
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const getState = async (event, state) => {
        const data = {
            country_code: event
        }
        const response = await axios.post(`${host}/find-countrycode`, data);
        console.log(response);
        if (response.data.error == false) {
            if (state == '') {
                setStates(response.data.data);
            } else {
                response.data.data.forEach((val) => {
                    if (state == val.short_name) {
                        setValue('state', val.short_name)
                    }
                })
                setStates(response.data.data);
            }
        }
    };

    const handleDOBChange = (e) => {
        const dob = e.target.value;
        setValue("DOB", dob);
        const age = calculateAge(dob);
        setValue("age", age);
    };

    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
            age--;
        }
        return age;
    };

    const handlePhoneInputChange = (value, country, e, formattedValue) => {
        setValue('contact_number', formattedValue || value);
    };

    const handleAlternatePhoneInputChange = (value, country, e, formattedValue) => {
        setValue('alt_contact_number', formattedValue || value);
    };

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        const maxSizeInBytes = 1 * 1024 * 1024;
        for (let i = 0; i < selectedFiles.length; i++) {
            if (selectedFiles[i].size > maxSizeInBytes) {
                setErrorMessage(`File ${selectedFiles[i].name} size exceeds the limit (1MB)`);
                event.target.value = null;
                return;
            }
        }
        // setFiles(Array.from(selectedFiles));
        setValue('Upload_File', Array.from(selectedFiles));
        setErrorMessage('');
    };

    const proficiencyTestValue = watch('Proficiency_Test');

    const onSubmit = async (data) => {
        setLoading(true);
        const formData = new FormData();
        const url = window.location.href;
        const urlObject = new URL(url);
        const id = urlObject.searchParams.get('id');
        formData.append('campagin_id', id);
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        try {
            const response = await axios.post(`${host}/campaignlead-add`, formData);
            setLoading(false);
            if (response.data.error == true) {
                MySwal.fire({
                    icon: "warning",
                    title: "Warning!",
                    text: `${response.data.message}`,
                });
            } else {
                MySwal.fire({
                    icon: "success",
                    title: "Success!",
                    text: `${response.data.message}`,
                });
                reset();
                setErrorMessage('');
                setDefaultCountryNumber('in');
            }
        } catch (error) {
            setLoading(false);
            MySwal.fire({
                icon: "error",
                title: "Error!",
                text: "An error occurred while adding the lead. Please try again.",
            });
        }
    };

    const handleClose = () => {
        reset();
        setLoading(false);
        setErrorMessage('');
        setDefaultCountryNumber('in')
        setStates([]);
    };

    return (
        <>
            <div>
                <Offcanvas.Body bsPrefix="offcanvaspadingbody">
                    <form onSubmit={handleSubmit(onSubmit)} className="sub-form-cls">
                        <div className="modal-body modalpadding" style={{
                            width: "50%", margin: "auto",
                            backgroundColor: "rgb(255 255 255)",
                            boxShadow: "0 0 30px 0px #0000006b;"
                        }}>
                            <div className="row" style={{ backgroundColor: "antiquewhite" }}>
                                <div className="col-md-12 mb-2 bg-color">
                                    Basic Information
                                </div>
                                {/* <div className="form-group col-md-4">
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        name="prefix"
                                        {...register('prefix', { required: 'Please select a Name' })}
                                    >
                                        <option value="">Select</option>
                                        <option value="Mr.">Mr. </option>
                                        <option value="Ms.">Ms.</option>
                                        <option value="Mrs.">Mrs.</option>
                                    </select>
                                    {errors.prefix && (
                                        <div className="error-message">
                                            {errors.prefix.message}
                                        </div>
                                    )}
                                </div> */}

                                <div className="form-group col-md-6">
                                    <label>Full Name</label>
                                    <br />
                                    <input
                                        type="text"
                                        placeholder="First name"
                                        className="form-control"
                                        name="first_name"
                                        {...register('first_name', { required: 'Please enter first name' })}

                                    />
                                    {errors.first_name && (
                                        <div className="error-message">
                                            {errors.first_name.message}
                                        </div>
                                    )}
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Email</label>
                                    <br />
                                    <input
                                        type="email"
                                        placeholder="Enter Email"
                                        name="email"
                                        className="form-control"
                                        {...register('email', { required: 'Please enter the email' })}
                                    />
                                    {errors.email && (
                                        <div className="error-message">
                                            {errors.email.message}
                                        </div>
                                    )}
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Contact No.</label>
                                    <br />
                                    <PhoneInput
                                        country={defaultCountryNumber}
                                        onChange={handlePhoneInputChange}
                                        inputProps={{
                                            name: 'contact_number',
                                            onBlur: () => register('contact_number', {
                                                required: 'Please enter the contact number',
                                                validate: value => value && value.replace(/\D/g, '').length >= 7 || 'Contact number must have at least 7 digits'
                                            }), // onBlur registration
                                            className: `form-control form-input-number ${errors.contact_number ? 'is-invalid' : ''}`,
                                        }}
                                    />
                                    {errors.contact_number && (
                                        <div className="error-message">{errors.contact_number.message}</div>
                                    )}
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Country Of Residence</label>
                                    <br />
                                    <select
                                        className="form-control"
                                        aria-label="Default select example"
                                        name="country"
                                        {...register('country', { required: 'Please select the country' })}
                                        onChange={(event) => getState(event.target.value)}
                                    >
                                        <option value="">
                                            Select
                                        </option>
                                        {countries.map((country) => (
                                            <option value={country.short_name}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.country && (
                                        <div className="error-message">
                                            {errors.country.message}
                                        </div>
                                    )}
                                </div>

                                <div className="form-group col-md-6">
                                    <label>State</label>
                                    <br />
                                    <select
                                        className="form-control"
                                        aria-label="Default select example"
                                        name="state"
                                        {...register('state', { required: 'Please select the state' })}
                                    >
                                        <option value="">
                                            Select
                                        </option>
                                        {states.map((state) => (
                                            <option value={state.short_name}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.state && (
                                        <div className="error-message">
                                            {errors.state.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-12 my-3 bg-color">
                                    Query Notes
                                </div>

                                <div className="form-group col-md-12">
                                    <label>Query Type</label>
                                    <br />
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        name="Lead_Type"
                                        {...register('Lead_Type', { required: 'Please select lead type' })}
                                    >
                                        <option value="">Select</option>
                                        {leadTypeList.data && leadTypeList.data.length > 0 ? (
                                            leadTypeList.data.map((val, key) => (
                                                <option value={val.lead_type}>{val.lead_type}</option>
                                            ))
                                        ) : null}
                                    </select>
                                    {errors.Lead_Type && (
                                        <div className="error-message">{errors.Lead_Type.message}</div>
                                    )}
                                </div>

                                <div className="form-group col-md-12">
                                    <label>Notes</label>
                                    <br />
                                    <div className="form-floating">
                                        <textarea
                                            className="form-control"
                                            placeholder="Leave a comment here"
                                            id="floatingTextarea"
                                            name="note"
                                            {...register('note', { required: 'Please enter the note' })}
                                        ></textarea>
                                        <label htmlFor="floatingTextarea">Notes</label>
                                    </div>
                                    {errors.note && (
                                        <div className="error-message">
                                            {errors.note.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="text-center">
                                {loading ? (
                                    <button className="btn btn-primary me-2" disabled>
                                        Loading...
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-primary me-2 button-ed">
                                        Save
                                    </button>
                                )}
                                <button type="reset" onClick={handleClose} className="btn btn-secondary button-ed">
                                    Cancel
                                </button>
                            </div>
                        </div>

                    </form>
                </Offcanvas.Body>
            </div >
        </>
    )
}

export default Lead;