import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from "axios";
import Swal from "sweetalert2";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import checklogin from "./checkLogin";

const Activity = () => {
  checklogin();
  const [data, setData] = useState([]);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [updatemodal, setUpdateModal] = useState(false);
  const toggle = () => {
    setModal(false);
    formik.resetForm();
  };
  const toggleOpen = () => setModal(true);
  const updatetogglecloase = () => setUpdateModal(!updatemodal);
  const [searchText, setSearchText] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const updatetoggle = (userId) => {
    setLoading(true);
    axios
      .post(`${host}/GetByIdRole`, { id: userId })
      .then((response) => {
        setLoading(false);
        const userData = response.data;
        const initialValues = {
          id: userData.data?._id || "",
          first_name: userData.data?.first_name || "",
          last_name: userData.data?.last_name || "",
          email: userData.data?.email || "",
          contact_number: userData.data?.contact_number || "",
          password: userData.data?.password || "",
          conpassword: userData.data?.password || "",
          job_role: userData.data?.job_role || "",
        };
        updateformik.setValues(initialValues);
        setUpdateModal(!updatemodal);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchCountryCodes();
  }, []);

  const fetchData = async () => {
    try {
      const item = {
        user_id: localStorage.getItem('userId')
      }
      const response = await axios.post(`${host}/GetAllRole`, item);
      if (response.data.error == false) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const fetchCountryCodes = async () => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      const data = await response.json();
      const codes = data.map((country) => country.cca2);
      setCountryCodes(codes);
      setSelectedCountryCode(codes[0]);
    } catch (error) {
      console.error('Error fetching country codes:', error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let data = {
          id: id,
        };
        const response = await axios.post(`${host}/DeleteRole`, data);
        setLoading(false);
        if (response.data.error == false) {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: `${response.data.message}`,
          });
        }
      } else {
        MySwal.fire({
          icon: "warning",
          title: "warning!",
          text: `Your data successfully safe`,
        });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      user_id: localStorage.getItem("userId") || "",
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      email: userData?.email || "",
      contact_number: userData?.contact_number || "",
      password: userData?.password || "",
      conpassword: userData?.password || "",
      job_role: userData?.job_role || "",
    },

    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      contact_number: Yup.string()
        .required("Contact Number is required"),
      password: Yup.string().required("Password is required"),
      conpassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      job_role: Yup.string().required("Job Role is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      axios.post(`${host}/AddRole`, values, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setLoading(false);
          if (response.data.error == false) {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: `${response.data.message}`,
            });
            toggle();
            setTimeout(() => {
              fetchData();
            }, 1000);
          } else {
            MySwal.fire({
              icon: "warning",
              title: "warning!",
              text: `${response.data.message}`,
            });
          }
        })
        .catch((error) => {
          MySwal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while saving your data.",
          });
        });
    },
  });

  const updateformik = useFormik({
    initialValues: {
      id: userData?._id || "",
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      email: userData?.email || "",
      contact_number: userData?.contact_number || "",
      password: userData?.password || "",
      conpassword: userData?.password || "",
      job_role: userData?.job_role || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      contact_number: Yup.string()
        .required("Contact Number is required"),
      password: Yup.string().required("Password is required"),
      conpassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      // job_role: Yup.string().required("Job Role is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post(`${host}/UpdateRole`, values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.error == false) {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: `${response.data.message}`,
            });
            updatetogglecloase();
            setTimeout(() => {
              fetchData();
            }, 1000);
          } else {
            MySwal.fire({
              icon: "warning",
              title: "warning!",
              text: `${response.data.message}`,
            });
          }
        })
        .catch((error) => {
          MySwal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while saving your data.",
          });
        });
    },
  });

  let paginatedData = [];
  if (Array.isArray(data)) {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    paginatedData = data.slice(startIndex, endIndex);
  } else {
    console.error("Data is not an array.");
  }

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * perPage,
      sortable: true,
      width: '100px !important',
    },
    {
      name: "Full Name",
      selector: (row) => (
        <span>{`${row.first_name} ${row.last_name}`}</span>
      ),
      sortable: true,
      width: "200px !important"
    },
    {
      name: "Job Role",
      selector: (row) => row.job_role == '' ? "Admin" : row.job_role,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "250px !important"
    },
    {
      name: "Contact no.",
      selector: (row) => '+' + row.contact_number,
      sortable: true,
    },
    {
      name: "Password",
      selector: (row) => row.password,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.status == 1 ? <button class="btn btn-primary position-relative" onClick={() => updateStatus(row._id, 0)}>
        Active</button> : <button class="btn btn-danger position-relative" onClick={() => updateStatus(row._id, 1)}>
        Deactive</button>),
      sortable: true,
    },
    {
      name: "View Leads",
      selector: (row) => (
        <button class="btn btn-primary position-relative" onClick={() => getSpecificLeads(row._id)}>
          View Leads
          {/* <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            99+
            <span class="visually-hidden">unread messages</span>
          </span> */}
        </button>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.id,
      width: "100px !important",
      sortable: true,
      cell: (row) => (
        <div>
          {/* Update Icon */}
          <span
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => updatetoggle(row._id)}
          >
            <i className="fas fa-edit" title="Update"></i>
          </span>

          {/* Delete Icon */}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(row._id)}
          >
            <i className="fas fa-trash-alt" title="Delete"></i>
          </span>
        </div>
      ),
    },
  ];

  const updateStatus = async (id, status) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action will update the status of the item.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, keep it closed'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        const item = {
          _id: id,
          status: status
        }
        const response = await axios.post(`${host}/update-status`, item);
        setLoading(false);
        if (response.data.error == false) {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: `${response.data.message}`,
          });
        }
      }
    });
  }

  const getSpecificLeads = async (id) => {
    navigate('/specific-leads?id=' + id)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData =
    data && data.data
      ? data.data.filter((item) => {
        return (
          item.first_name != "" &&
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText.toLowerCase())
          )
        )
      }
      )
      : [];

  const handlePhoneInputChange = value => {
    formik.setFieldValue('contact_number', value);
  };

  return (
    <>
      <Sidebar />
      <CustomNavbar />
      <div className="main-content">
        <div className="container-fluid">
          <div className="topbox">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="left-logo">
                  <div>
                    <img src={profile2} alt="Logo" className="logo" />
                  </div>
                  <p className="topboxttex">Lead Management</p>
                </div>
              </div>
              {/* <div className="right-logo"> */}
              <div className="col-md-4 ">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Leads
                    </li>
                  </ol>
                </nav>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 ">
              <div className="d-flex justify-content-between  me-2">
                <div className="wrap">
                  <div className="search">
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Search"
                      style={{ borderRadius: "7px", height: "35px" }}
                    />
                    {/* <button type="submit" className="searchButton">
                      Search
                    </button> */}
                  </div>
                </div>

                <button className="buttonAdd" onClick={toggleOpen}>
                  Add +
                </button>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="row tab-content">
                {/* <div
                  id="list-view"
                  className="tab-pane fade active show col-lg-12"
                > */}
                <div className="card">
                  <div className="card-header">
                    <DataTable
                      className="custom-data-table activity-data-table"
                      columns={columns}
                      data={filteredData}
                      pagination
                      paginationTotalRows={data.length}
                      onChangePage={handlePageChange}
                      paginationPerPage={perPage}
                      paginationRowsPerPageOptions={[10, 20, 30]}
                      onChangeRowsPerPage={handlePerPageChange}
                      paginationPerPageLabel="Rows per page"
                    />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="right-aligned-modal myrightmodal"
        >
          <ModalHeader toggle={toggle}>Add</ModalHeader>
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label for="first_name">First Name</Label>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="Enter Name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <div className="error">{formik.errors.first_name}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="last_name">Last Name</Label>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Enter Name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <div className="error">{formik.errors.last_name}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="contact_number">Contact Number </Label>
                <PhoneInput
                  country={'in'} // Replace 'us' with your default country code
                  value={formik.values.contact_number}
                  onChange={handlePhoneInputChange}
                  onBlur={() => formik.setFieldTouched('contact_number', true)} // Mark field as touched
                  inputProps={{
                    name: 'contact_number',
                    className: `form-control ${formik.touched.contact_number && formik.errors.contact_number ? 'is-invalid' : ''}`,
                  }}
                />
                {formik.touched.contact_number && formik.errors.contact_number ? (
                  <div className="error">{formik.errors.contact_number}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="password">Password </Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter Password "
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="conpassword">Confim Password </Label>
                <Input
                  type="password"
                  name="conpassword"
                  id="conpassword"
                  placeholder="Enter Confim  Password "
                  value={formik.values.conpassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.conpassword && formik.errors.conpassword ? (
                  <div className="error">{formik.errors.conpassword}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="job_role">Job Role </Label>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle caret>
                    {formik.values.job_role || "Select Job Role"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() =>
                        formik.setFieldValue("job_role", "Pre-Sales")
                      }
                    >
                      Pre-Sales
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => formik.setFieldValue("job_role", "Sales")}
                    >
                      Sales
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        formik.setFieldValue("job_role", "Filing Manager")
                      }
                    >
                      Filing Manager
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        formik.setFieldValue("job_role", "Filing Team")
                      }
                    >
                      Filing Team
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        formik.setFieldValue("job_role", "Admin")
                      }
                    >
                      Admin
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        formik.setFieldValue("job_role", "Influencer")
                      }
                    >
                      Influencer
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                {formik.touched.job_role && formik.errors.job_role ? (
                  <div className="error">{formik.errors.job_role}</div>
                ) : null}
              </FormGroup>

              <ModalFooter>
                {loading ? (
                  <Button
                    style={{
                      background:
                        "linear-gradient(175deg, #1B0B54 0%, #090221 100%)",
                      color: "#fff",
                      borderRadius: "50px",
                    }}
                  >
                    Loading....
                  </Button>
                ) : (
                  <Button
                    style={{
                      background:
                        "linear-gradient(175deg, #1B0B54 0%, #090221 100%)",
                      color: "#fff",
                      borderRadius: "50px",
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                )}
                <Button color="secondary" type="reset" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </Modal>
      </div >
      <div>
        <Modal
          isOpen={updatemodal}
          toggle={updatetoggle}
          className="right-aligned-modal"
        >
          <ModalHeader toggle={updatetogglecloase}>Update</ModalHeader>
          <ModalBody>
            <form onSubmit={updateformik.handleSubmit}>
              <FormGroup>
                <Label for="first_name">First Name</Label>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="Enter Name"
                  value={updateformik.values.first_name}
                  onChange={updateformik.handleChange}
                  onBlur={updateformik.handleBlur}
                />
                {updateformik.touched.first_name &&
                  updateformik.errors.first_name ? (
                  <div className="error">{updateformik.errors.first_name}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="last_name">Last Name</Label>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Enter Name"
                  value={updateformik.values.last_name}
                  onChange={updateformik.handleChange}
                  onBlur={updateformik.handleBlur}
                />
                {updateformik.touched.last_name &&
                  updateformik.errors.last_name ? (
                  <div className="error">{updateformik.errors.last_name}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Email"
                  value={updateformik.values.email}
                  onChange={updateformik.handleChange}
                  onBlur={updateformik.handleBlur}
                />
                {updateformik.touched.email && updateformik.errors.email ? (
                  <div className="error">{updateformik.errors.email}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="contact_number">Contact Number </Label>
                <PhoneInput
                  country={'in'} // Replace 'in' with your default country code
                  value={updateformik.values.contact_number}
                  onChange={(value) => updateformik.setFieldValue('contact_number', value)}
                  onBlur={() => updateformik.setFieldTouched('contact_number', true)}
                  inputProps={{
                    name: 'contact_number',
                    className: `form-control ${updateformik.touched.contact_number && updateformik.errors.contact_number ? 'is-invalid' : ''}`,
                    placeholder: 'Enter Number',
                  }}
                />
                {updateformik.touched.contact_number && updateformik.errors.contact_number ? (
                  <div className="error">
                    {updateformik.errors.contact_number}
                  </div>
                ) : null}
              </FormGroup>


              <FormGroup>
                <Label for="password">Password </Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter Password "
                  value={updateformik.values.password}
                  onChange={updateformik.handleChange}
                  onBlur={updateformik.handleBlur}
                />
                {updateformik.touched.password &&
                  updateformik.errors.password ? (
                  <div className="error">{updateformik.errors.password}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="conpassword">Confim Password </Label>
                <Input
                  type="password"
                  name="conpassword"
                  id="conpassword"
                  placeholder="Enter Confim  Password "
                  value={updateformik.values.conpassword}
                  onChange={updateformik.handleChange}
                  onBlur={updateformik.handleBlur}
                />
                {updateformik.touched.conpassword &&
                  updateformik.errors.conpassword ? (
                  <div className="error">{updateformik.errors.conpassword}</div>
                ) : null}
              </FormGroup>

              <ModalFooter>
                {loading ? (
                  <Button
                    style={{
                      background:
                        "linear-gradient(175deg, #1B0B54 0%, #090221 100%)",
                      color: "#fff",
                      borderRadius: "50px",
                    }}
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    style={{
                      background:
                        "linear-gradient(175deg, #1B0B54 0%, #090221 100%)",
                      color: "#fff",
                      borderRadius: "50px",
                    }}
                    type="submit"
                  >
                    Update
                  </Button>
                )}
                <Button color="secondary" onClick={updatetogglecloase}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Activity;
