import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import checklogin from "./checkLogin";
import axios from "axios";
import Swal from "sweetalert2";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import infomationLoginUser from "./LoginUSerInfo";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";

function Campaign() {

  const [data, setData] = useState([]);
  const MySwal = withReactContent(Swal);
  const [searchText, setSearchText] = useState("");
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [id, editId] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { register, handleSubmit, watch, formState: { errors }, setValue, reset } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [influencer, setInfluencer] = useState([]);

  useEffect(() => {
    checklogin();
    infomationLoginUser();
    fetchData();
    getInfluencer();
  }, []);

  const getInfluencer = async () => {
    try {
      const findInfluencer = await axios.get(`${host}/influencer-list`);
      if (findInfluencer.data.error == false) {
        setInfluencer(findInfluencer.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      Object.keys(apiResponse.data).forEach((key) => {
        setValue(key, apiResponse.data[key]);
      });
    }
  }, [apiResponse, setValue]);

  const handleClose = () => {
    reset()
    setShow(false);
  }

  const handleShow = () => {
    editId("");
    setShow(true);
  };

  var userId = localStorage.getItem("userId");

  const Submit = async (data) => {
    setLoading(true);
    if (id == '') {
      delete data._id;
      data.user_id = userId;
      // data.url = "http://localhost:3001/lead";
      data.url = "https://checkcheckservices.in/lead"
      try {
        const response = await axios.post(`${host}/campaign-add`, data)
        setLoading(false);
        if (response.data.error == false) {
          MySwal.fire({
            icon: 'success',
            title: 'Success!',
            text: `${response.data.message}`,
          });
          handleClose();
          fetchData()
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Error!',
            text: `${response.data.message}`,
          });
        }
      } catch (error) {
        MySwal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while submitting the data.',
        });
      }
    } else {
      try {
        const response = await axios.post(`${host}/update-campaign-id`, data);
        setLoading(false);
        if (response.data.error == false) {
          MySwal.fire({
            icon: 'success',
            title: 'Success!',
            text: `${response.data.message}`,
          });
          handleClose();
          fetchData()
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Error!',
            text: `${response.data.message}`,
          });
        }
      } catch (error) {
        MySwal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while submitting the data.',
        });
      }
    }
  }

  const fetchData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await fetch(`${host}/list-campaign-all?userId=${userId}`);
      if (response.ok) {
        const apiData = await response.json();
        setData(apiData); // Assuming setData is defined somewhere in your code to update the state
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const leadGetById = async (id) => {
    try {
      const response = await axios.post(`${host}/list-campaign-id`, {
        id: id,
      });
      setApiResponse(response.data);
      editId(response.data._id);
      setShow(true);
    } catch (error) {
      console.error(error);
    }
  };

  const campaignDelete = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          id: id,
        };
        const response = await axios.post(`${host}/delete-campaign`, data);
        if (response.data.error == false) {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: `${response.data.message}`,
          });
        }
      } else {
        MySwal.fire({
          icon: "warning",
          title: "warning!",
          text: `Your data successfully safe`,
        });
      }
    });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  let paginatedData = [];
  if (Array.isArray(data)) {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    paginatedData = data.slice(startIndex, endIndex);
  } else {
    console.error("Data is not an array.");
  }

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px !important"
    },

    {
      name: "Campagin Name",
      selector: (row) => (
        <div className="">
          <span>{`${row.campagin_name}`}</span>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Start Date",
      selector: (row) => <div>{row.start_date}</div>,
      sortable: true,
    },

    {
      name: "End Date",
      selector: (row) => <>{row.end_date}</>,
      sortable: true,
    },
    {
      name: "Influencer Name",
      selector: (row) => <>{row.influencer_name}</>,
      sortable: true,
    },
    {
      name: "Notes",
      selector: (row) => row.notes,
      sortable: true,
    },
    {
      name: "Url",
      selector: (row) => <a href={row.url}>{row.url}</a>,
      sortable: true,
    },
    {
      name: "View Leads",
      selector: (row) => (
        <button class="btn btn-primary position-relative" onClick={() => getSpecificLeads(row._id)}>
          View Leads
        </button>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.id,
      width: "100px !important",
      sortable: true,
      cell: (row) => (
        <div>
          <span style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => leadGetById(row._id)}
          >
            <i className="fas fa-edit" title="Update"></i>
          </span>

          <span style={{ cursor: "pointer" }}
            onClick={() => campaignDelete(row._id)}
          >
            <i className="fas fa-trash-alt" title="Delete"></i>
          </span>
        </div>
      ),
    },
  ];

  const getSpecificLeads = (id) => {
    navigate('/campaignspecific-leads?id=' + id)
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  const filteredData =
    data && data.data
      ? data.data.filter((row) => {
        return (
          Object.values(row).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText.toLowerCase())
          )
        );
      })
      : [];

  return (
    <>
      <Sidebar />
      <CustomNavbar />
      <div className="main-content">
        <div className="container-fluid">
          <section className="topbox">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <div className="left-logo">
                    <div>
                      <img src={profile2} alt="Logo" className="logo" />
                    </div>
                    <p className="topboxttex">Lead Management</p>
                  </div>
                </div>
                {/* <div className="right-logo"> */}
                <div className="col-md-4 ">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="dashboard">Dashboard</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Leads
                      </li>
                    </ol>
                  </nav>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-md-12 pt-3 my-3">
              <div className="d-flex align-items-center  justify-content-between">
                <div className="wrap">
                  <div className="search">
                    <input type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Search"
                      style={{ borderRadius: "7px", height: "35px" }} />
                    {/* <button type="submit" className="searchButton">
                      Search
                    </button> */}
                  </div>
                </div>
                <button class="buttonAdd" onClick={handleShow}>
                  + Add
                </button>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="row tab-content">
                <div
                  id="list-view"
                  className="tab-pane fade active show col-lg-12"
                >
                  <div className="card">
                    <div className="card-header">
                      <DataTable
                        className="custom-data-table campaign-data-table"
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={data.length}
                        onChangePage={handlePageChange}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30]} // Customize rows per page options
                        onChangeRowsPerPage={handlePerPageChange}
                        paginationPerPageLabel="Rows per page"
                        onSelectedRowsChange={({ selectedRows }) => {
                          setSelectedLeadIds(
                            selectedRows.map((row) => row._id)
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          {id == "" ? (
            <Offcanvas.Title bsPrefix="titlestl">Add</Offcanvas.Title>
          ) : (
            <Offcanvas.Title bsPrefix="titlestl">Update</Offcanvas.Title>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={handleSubmit(Submit)}>
            <div className="row modalpadding">
              <input type="hidden" name="_id"  {...register('_id')} />
              <div className="form-group col-md-12">
                <label>Campaign Name</label>
                <br />
                <input
                  type="text"
                  name="campagin_name"
                  className="form-control"
                  placeholder="Enter Campaign Name"
                  {...register('campagin_name', { required: 'Please enter the campaign name' })}
                />
                {errors.campagin_name && (
                  <div className="error-message">
                    {errors.campagin_name.message}
                  </div>
                )}
              </div>

              <div className="form-group col-md-12">
                <label>Start Date</label>
                <br />
                <input
                  type="date"
                  name="start_date"
                  className="form-control"
                  {...register('start_date', { required: 'Please select the start date' })}
                />
                {errors.start_date && (
                  <div className="error-message">
                    {errors.start_date.message}
                  </div>
                )}
              </div>

              <div className="form-group col-md-12">
                <label>Last Date</label>
                <br />
                <input
                  type="date"
                  name="end_date"
                  className="form-control"
                  {...register('end_date', { required: 'Please select the end date' })}
                />
                {errors.end_date && (
                  <div className="error-message">
                    {errors.end_date.message}
                  </div>
                )}
              </div>

              {id == "" ? (
                <div className="form-group col-md-12">
                  <label>Select Influencer</label>
                  <br />
                  <select name="influencer" className="form-select"
                    {...register('influencer', { required: 'Please select the influencer' })}>
                    <option value="">Select an influencer</option>
                    {influencer.map((val, key) => (
                      <option key={key} value={val._id}>
                        {val.first_name} {val.last_name}
                      </option>
                    ))}
                  </select>
                  {errors.influencer && (
                    <div className="error-message">
                      {errors.influencer.message}
                    </div>
                  )}
                </div>
              ) : ('')}

              <div className="col-md-12">
                <label>Notes</label>
                <br></br>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea"
                    name="notes"
                    {...register('notes', { required: 'Please enter the notes' })}
                  ></textarea>
                  <label for="floatingTextarea">Comments</label>
                </div>
                {errors.notes && (
                  <div className="error-message">
                    {errors.notes.message}
                  </div>
                )}
              </div>

              <div class="col-md-12 text-center mt-4">
                {id == "" ? (
                  loading ? (
                    <button className="btn btn-primary me-2">
                      Loading...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary me-2">
                      Save
                    </button>
                  )
                ) : (
                  loading ? (
                    <button className="btn btn-primary me-2">
                      Loading...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary me-2">
                      Update
                    </button>
                  )
                )}
                <button type="reset" class="btn btn-secondary" onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas >
    </>
  );
}

export default Campaign;
