const checklogin = (req, res) => {
  let checksalestoken = localStorage.getItem("SalesToken");
  let checkfmtoken = localStorage.getItem("FilingManagerToken");
  let checkPstoken = localStorage.getItem("Pre_SalesToken");
  let checkFttoken = localStorage.getItem("FilingTeamToken");
  let checkinfluencerToken = localStorage.getItem("InfluencerToken");
  let checkAdmintoken = localStorage.getItem("Admintoken");
  if (
    checksalestoken == null &&
    checkfmtoken == null &&
    checkPstoken == null &&
    checkFttoken == null &&
    checkinfluencerToken == null &&
    checkAdmintoken == null
  ) {
    // window.location = "http://192.168.29.47:3001/";
    window.location = "https://checkcheckservices.in/";
    return;
  }

  const lastCheckDate = localStorage.getItem('lastCheckDate');
  let now = new Date();
  let day = now.getDate().toString();
  if ((lastCheckDate == null) || (lastCheckDate !== day)) {
    localStorage.setItem('lastCheckDate', day);
    localStorage.removeItem("SalesToken");
    localStorage.removeItem("FilingManagerToken");
    localStorage.removeItem("Pre_SalesToken");
    localStorage.removeItem("FilingTeamToken");
    localStorage.removeItem("InfluencerToken");
    localStorage.removeItem("Admintoken");
    window.location = "https://checkcheckservices.in/";
  }
};

export default checklogin;
