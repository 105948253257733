import React, { useEffect, useState } from "react";
import bell from "../Assets/Images/bells.png";
import chand from "../Assets/Images/celender.jpg";
import Profile from "../Assets/Images/profile.jpg";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import utils from "../Components/utils"
import axios from 'axios';

const Navbar = () => {
  const [profile, setProfile] = useState([]);
  const [getResponse, setGetResponse] = useState([]);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    findProfile();
    fetchNotification();
  }, []);

  const fetchNotification = async () => {
    try {
      if (localStorage.getItem('Pre_SalesToken') == null) {
        const userId = localStorage.getItem('userId')
        if (!userId) {
          throw new Error('User ID not found in local storage');
        } else {
          const response = await axios.post(`${utils}/find-notification`, { user_id: userId });
          if (response.data.error == false) {
            setGetResponse(response.data.data);
          } else {
            MySwal.fire({
              icon: "warning",
              title: "Warning!",
              text: `${response.data.message}`,
            });
          }
        }
      } else {
        const response = await axios.get(`${utils}/find-presalesnotification`);
        if (response.data.error == false) {
          setGetResponse(response.data.data);
        } else {
          MySwal.fire({
            icon: "warning",
            title: "Warning!",
            text: `${response.data.message}`,
          });
        }
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }

  const updateStatus = async (id) => {
    try {
      if (!id) {
        throw new Error('Please provide the id');
      } else {
        const data = {
          _id: id,
          status: 'inactive'
        }
        const response = await axios.post(`${utils}/notification-statusupdate`, data);
        if (response.data.error == false) {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          fetchNotification();
        } else {
          MySwal.fire({
            icon: "warning",
            title: "Warning!",
            text: `${response.data.message}`,
          });
        }
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }

  const findProfile = async () => {
    const item = {
      _id: localStorage.getItem('userId')
    }
    const response = await axios.post(`${utils}/emp-profile`, item);
    if (response.data.error == false) {
      setProfile(response.data.data)
    }
  }

  const handleLogout = () => {
    const tokensToRemove = [
      "SalesToken",
      "FilingManagerToken",
      "Pre_SalesToken",
      "FilingTeamToken",
      "InfluencerToken",
      "Admintoken",
      "userId",
    ];
    tokensToRemove.forEach((token) => {
      if (localStorage.getItem(token)) {
        localStorage.removeItem(token);
      }
    });
    // window.location = "http://192.168.29.47:3001/";
    // window.location = "http://localhost:3001/";
    window.location = "https://checkcheckservices.in/";
  };

  return (
    <section className="mynavbar">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="leftlogo">
              {/* Include your logo or any other content here */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="rightlogo">
              <div>{getResponse.length}</div>
              <img src={bell} alt="Notification" className="notification" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={fetchNotification} />
              <img src={chand} alt="Notification" className="notification" />
              <span className="demo-text">{profile.first_name} {profile.last_name}</span>
              <span className="demo-text">Role: {profile.job_role}</span>
              <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" data-bs-toggle="modal" data-bs-target="#logoutModal" />
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="logoutModalLabel">Logout</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to logout?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
              <button type="button" class="btn btn-primary" onClick={handleLogout}>Yes, Logout</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Notification</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {getResponse ? (
                // Map through getResponse to display notifications
                getResponse.map((val, key) => (
                  <div key={key} style={styles.notification}>
                    <div style={styles.notificationText}>{val.notification}</div>
                    <button className="btn btn-primary view-button" onClick={() => updateStatus(val._id)}>Mark As Read</button>
                  </div>
                ))
              ) : (
                // If getResponse is not available, display a loading message or any alternative content
                <p>Loading notifications...</p>
              )}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

const styles = {
  notification: {
    display: 'flex', /* Use flexbox layout */
    alignItems: 'center', /* Align items vertically center */
    justifyContent: 'space-between', /* Distribute space between items */
    marginBottom: '10px', /* Add some margin between notifications */
  },

  notificationText: {
    flexGrow: 1, /* Allow the text to take up remaining space */
    marginRight: '10px', /* Add some space to the right of the text */
  }
};


export default Navbar;
